<template>
  <div class="reportStructure">
    <!--编辑报告页面-->
    <div class="title-box">
      <div class="title">
        {{ title }}
      </div>
      <div class="btn-box">
        <el-button type="text" class="btn-text" @click="goBack"
          >上一步</el-button
        >
        <el-button type="primary" class="cli-btn" @click="subFrom"
          >完成
          <i class="el-icon-arrow-right el-icon--right"></i>
        </el-button>
      </div>
    </div>
    <div class="cnt-box">
      <p style="font-size: 20px">
        {{ allTitle }}
        {{ $route.query.type | setTitle(menuList) }}
      </p>
      <h3>报告结构</h3>
      <!--封面的盒子-->
      <div v-if="getItem('cover')" class="fm-box after">
        <div class="fm-item fm-title">
          <p>封面</p>
        </div>
        <!--第二个盒子-->
        <div
          class="fm-item add-img"
          :class="{ trueClass: subFromList.coverMap }"
        >
          <i v-if="!subFromList.coverMap" class="el-icon-plus"></i>
          <img class="img-style" :src="subFromList.coverMap" alt="" />
          <input
            ref="imgNode"
            type="file"
            accept="image/gif,image/jpeg,image/jpg,image/png"
            class="add-input"
            @change="changeImg($event, 'coverMap')"
          />
          <!-- <input type="file" class="file" @change="tirggerFile($event,'c')"> -->
        </div>
        <div class="fm-item">
          <el-dropdown class="el-menu-list">
            <span class="el-dropdown-link">
              <i class="el-icon-more"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="setImg('onoCoverImg')"
                >应用</el-dropdown-item
              >
              <!-- <el-dropdown-item @click.native="linkTo()">编辑</el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
          <img class="img-style" :src="onoCoverImg" alt="" />
        </div>
        <div class="fm-item">
          <el-dropdown class="el-menu-list">
            <span class="el-dropdown-link">
              <i class="el-icon-more"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="setImg('twoCoverImg')"
                >应用</el-dropdown-item
              >
              <!-- <el-dropdown-item @click.native="linkTo()">编辑</el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
          <img class="img-style" :src="twoCoverImg" alt="" />
        </div>
        <div class="fm-item">
          <el-dropdown class="el-menu-list">
            <span class="el-dropdown-link">
              <i class="el-icon-more"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="setImg('threeCoverImg')"
                >应用</el-dropdown-item
              >
              <!-- <el-dropdown-item @click.native="linkTo()">编辑</el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
          <img class="img-style" :src="threeCoverImg" alt="" />
        </div>
      </div>
      <!--扉页-->
      <div v-if="getItem('endPaper')" class="fm-box after">
        <div class="fm-item fm-title">
          <p>扉页</p>
        </div>
        <!--第二个盒子-->
        <div class="fm-item add-img">
          <i class="el-icon-plus"></i>
        </div>
      </div>
      <!--多个参数和子列表-->
      <div v-if="getItem('analyse')" class="cs-box after">
        <div class="left-title">成绩分析模板</div>
        <div class="right-cnt">
          <el-checkbox-group v-model="subFromList.reportStructures">
            <template v-if="$route.query.type == 0">
              <el-checkbox label="101">成绩概况</el-checkbox>
              <el-checkbox label="102">同层分段</el-checkbox>
              <el-checkbox label="103">学情跟踪</el-checkbox>
              <el-checkbox label="104">重点关注</el-checkbox>
              <el-checkbox label="105">提分建议</el-checkbox>
              <el-checkbox label="106">考点跟踪</el-checkbox>
              <el-checkbox label="999">提分训练</el-checkbox>
            </template>
            <template v-if="$route.query.type == 1">
              <el-checkbox label="201">班级成绩概况</el-checkbox>
              <el-checkbox label="202">班级分数段分布</el-checkbox>
              <el-checkbox label="203">班级试卷分析</el-checkbox>
              <el-checkbox label="204">班级知识点得分率分析</el-checkbox>
              <el-checkbox label="205">班级小题讲评分析</el-checkbox>
              <el-checkbox label="999">变式题汇总</el-checkbox>
            </template>
            <template v-if="$route.query.type == 2">
              <el-checkbox label="301">年级成绩概况</el-checkbox>
              <el-checkbox label="302">年级分数段分布</el-checkbox>
              <el-checkbox label="303">年级试卷分析</el-checkbox>
              <el-checkbox label="304">年级知识点得分率分析</el-checkbox>
              <el-checkbox label="305">年级小题讲评分析</el-checkbox>
              <el-checkbox label="999">变式题汇总</el-checkbox>
            </template>
            <template v-if="$route.query.type == 3">
              <el-checkbox label="401">群体成绩概况</el-checkbox>
              <el-checkbox label="402">群体分数段分布</el-checkbox>
              <el-checkbox label="403">群体试卷分析</el-checkbox>
              <el-checkbox label="404">群体知识点得分率分析</el-checkbox>
              <el-checkbox label="405">群体小题讲评分析</el-checkbox>
              <el-checkbox label="999">变式题汇总</el-checkbox>
            </template>
          </el-checkbox-group>
        </div>
      </div>
      <div v-if="getItem('试题二维码')" class="cs-box after">
        <div class="left-title">二维码推送</div>
        <div class="right-cnt">
          <el-radio v-model="subFromList.QRCode" label="1">开启</el-radio>
        </div>
      </div>
      <!--错因-->
      <div v-if="getItem('错因')" class="cs-box after">
        <div class="left-title">错因展示</div>
        <div class="right-cnt">
          <el-radio v-model="subFromList.wrongCause" label="1">开启</el-radio>
        </div>
      </div>
      <div v-if="getItem('analysis')" class="cs-box after">
        <div class="left-title">答案设置</div>
        <div class="right-cnt">
          <el-checkbox-group v-model="subFromList.answerSetting">
            <el-checkbox v-for="(v, i) in answerList" :key="i" :label="v.id">{{
              v.name
            }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div v-if="getItem('考点')" class="cs-box after">
        <div class="left-title">考点展示</div>
        <div class="right-cnt">
          <el-radio v-model="subFromList.testSite" label="1">开启</el-radio>
        </div>
      </div>
      <div v-if="getItem('回归教材')" class="cs-box after">
        <div class="left-title">回归教材展示</div>
        <div class="right-cnt">
          <el-radio v-model="subFromList.backToTextbook" label="1"
            >开启</el-radio
          >
        </div>
      </div>
      <div v-if="getItem('gradeNecessary')" class="cs-box after">
        <div class="left-title">年级必做题设置</div>
        <div class="right-cnt">
          <el-radio v-model="gradeQuestions.type" label="1">自动推送</el-radio>
          <el-radio v-model="gradeQuestions.type" label="2">手动推送</el-radio>
          <!--手动推送选择试题的盒子-->
          <div v-if="gradeQuestions.type == '2'" class="exam-box">
            <div class="exam-list">
              <template v-for="(v, i) in questionOrders">
                <div :key="i + 'box'">
                  <p>{{ v.examName }}</p>
                  <el-checkbox-group v-model="gradeMustQuestionNums[i].valArr">
                    <template v-for="(m, n) in v.examQuestionOrders">
                      <el-checkbox :key="n + '题'" :label="m"
                        >{{ m }}题</el-checkbox
                      >
                    </template>
                  </el-checkbox-group>
                </div>
              </template>
            </div>
            <div class="exam-list suc-box">
              <template v-for="(v, i) in gradeMustQuestionNums">
                <div :key="i + 'box1'">
                  <p>{{ v.examName }}必做题</p>
                  <el-checkbox-group v-model="gradeMustQuestionNums[i].valArr">
                    <template v-for="(m, n) in v.valArr">
                      <el-checkbox :key="n + '题'" :label="m"
                        >{{ m }}题</el-checkbox
                      >
                    </template>
                  </el-checkbox-group>
                </div>
              </template>
            </div>
          </div>
          <span v-if="gradeQuestions.type == '1'" style="margin-left: 12px"
            >自动推送必做题数量
          </span>
          <input
            v-if="gradeQuestions.type == '1'"
            v-model="gradeQuestions.quantity"
            type="text"
            placeholder="空"
            class="df-text"
          />
          <span v-if="gradeQuestions.type == '1'"> 题</span>
          <div>
            <span style="margin-right: 10px">插入位置</span>
            <el-radio v-model="gradeQuestions.position" label="1" disabled
              >个性题前</el-radio
            >
            <el-radio v-model="gradeQuestions.position" label="2" disabled
              >个性题后</el-radio
            >
            <el-radio
              v-model="gradeQuestions.position"
              label="3"
              disabled
              style="margin-right: 0"
            >
              个性题中
            </el-radio>
            <span v-if="gradeQuestions.position == '3'">第</span>
            <input
              v-if="gradeQuestions.position == '3'"
              v-model="gradeQuestions.positionNum"
              type="text"
              placeholder="空"
              class="df-text"
            />
            <span v-if="gradeQuestions.position == '3'"> 题</span>
          </div>
        </div>
      </div>
      <div v-if="getItem('classNecessary')" class="cs-box after">
        <div class="left-title">班级必做题设置</div>
        <div class="right-cnt">
          <el-radio v-model="classQuestions.type" label="1">自动推送</el-radio>
          <el-radio v-model="classQuestions.type" label="2">手动推送</el-radio>
          <!--手动推送选择试题的盒子-->
          <div v-if="classQuestions.type == '2'" class="exam-box">
            <div class="exam-list">
              <div class="class-item-box">
                <p>班级列表</p>
                <el-radio-group v-if="newKeys" v-model="classRadio" size="mini">
                  <el-radio
                    v-for="v in classMustQuestionNums"
                    :key="v.classNum"
                    :label="v.classNum"
                    >{{ v.classNum }}班</el-radio
                  >
                </el-radio-group>
              </div>
              <template v-for="(v, i) in questionOrders">
                <div
                  v-if="newKeys && classMustQuestionNums.length > 0"
                  :key="i + 'box'"
                >
                  <p>{{ v.examName }}</p>
                  <el-checkbox-group
                    v-model="
                      classMustQuestionNums.filter(
                        (i) => i.classNum == classRadio
                      )[0].questionOrders[i].questionItem
                    "
                  >
                    <template v-for="(m, n) in v.examQuestionOrders">
                      <el-checkbox :key="n + '题'" :label="m">
                        {{ m }}题</el-checkbox
                      >
                    </template>
                  </el-checkbox-group>
                </div>
              </template>
            </div>
            <div class="exam-list suc-box">
              <template v-for="(v, i) in classMustQuestionNums">
                <div
                  v-if="
                    newKeys &&
                    v.questionOrders.length > 0 &&
                    classMustQuestionNums[i].questionOrders
                  "
                  :key="i + 'box1'"
                >
                  <p>{{ v.classNum }}班必做题列表</p>
                  <!-- questionItem -->
                  <template
                    v-for="(val, index) in classMustQuestionNums[i]
                      .questionOrders"
                  >
                    <p
                      v-if="val.questionItem.length > 0"
                      :key="index + 'questionItemname'"
                      style="color: #999999"
                    >
                      {{ val.examName }}
                    </p>
                    <el-checkbox-group
                      :key="index + 'questionItem'"
                      v-model="val.questionItem"
                    >
                      <template v-for="(m, n) in val.questionItem">
                        <el-checkbox :key="n + '题'" :label="m"
                          >{{ m }}题</el-checkbox
                        >
                      </template>
                    </el-checkbox-group>
                  </template>
                </div>
              </template>
            </div>
          </div>
          <span v-if="gradeQuestions.type == '1'" style="margin-left: 12px"
            >自动推送必做题数量
          </span>
          <input
            v-if="classQuestions.type == '1'"
            v-model="classQuestions.quantity"
            type="text"
            placeholder="空"
            class="df-text"
          />
          <span v-if="classQuestions.type == '1'"> 题</span>
          <div>
            <span style="margin-right: 10px">插入位置</span>
            <el-radio v-model="classQuestions.position" label="1" disabled
              >个性题前</el-radio
            >
            <el-radio v-model="classQuestions.position" label="2" disabled
              >个性题后</el-radio
            >
            <el-radio
              v-model="classQuestions.position"
              label="3"
              disabled
              style="margin-right: 0"
            >
              个性题中
            </el-radio>
            <span v-if="classQuestions.position == '3'">第</span>
            <input
              v-if="classQuestions.position == '3'"
              v-model="classQuestions.positionNum"
              type="text"
              placeholder="空"
              class="df-text"
            />
            <span v-if="classQuestions.position == '3'"> 题</span>
          </div>
        </div>
      </div>
      <div v-if="getItem('answerArea')" class="cs-box after">
        <div class="left-title">作答区域的大小设置</div>
        <div class="right-cnt">
          <!--装列表的盒子-->
          <div class="region-box">
            <div class="region-left">
              <template v-for="(v, i) in regionListSuc">
                <div :key="i" class="region-list">
                  <span>{{ v.name }}</span>
                  <input
                    v-model="v.val"
                    type="text"
                    placeholder="空"
                    class="df-text"
                  />
                  <span style="margin-right: 8px">行；</span>
                </div>
              </template>
            </div>
            <!--<div>-->
            <i class="el-icon-plus" @click="dialogVisible = true"></i>
            <!--</div>-->
          </div>
        </div>
      </div>

      <div v-if="getItem('area')" class="cs-box after">
        <div class="left-title">签字区域的位置设置</div>
        <div class="right-cnt">
          <span>每隔</span>
          <input
            v-model="signatureText"
            type="text"
            placeholder="空"
            class="df-text"
          />
          <span style="margin-right: 12px">题插入签字区域、作答日期</span>
          <!-- <el-radio v-model="signature.type" label="1">横式</el-radio>
          <el-radio v-model="signature.type" label="2">竖式</el-radio> -->
        </div>
      </div>

      <div v-if="getItem('qrCode')" class="cs-box after">
        <div class="left-title">答案是否线上发布</div>
        <div class="right-cnt">
          <div>
            <el-radio v-model="resolve.type" label="1">是</el-radio>
            <el-radio v-model="resolve.type" label="2">否</el-radio>
          </div>
        </div>
        <div v-if="resolve.type == 1" class="da-fb-box">
          <el-radio v-model="resolve.releaseType" label="1">相对发布</el-radio>
          <el-radio v-model="resolve.releaseType" label="2">绝对发布</el-radio>
          <template v-for="(v, i) in resolve.resolveTime">
            <div v-if="resolve.releaseType == 1" :key="i" class="da-list-time">
              <template>
                <input
                  v-model="v.min"
                  type="text"
                  placeholder="空"
                  class="df-text"
                />
                题至
                <input
                  v-model="v.max"
                  type="text"
                  placeholder="空"
                  class="df-text"
                />题
              </template>
              <span style="margin-left: 8px"
                >生成报告后
                <input
                  v-model.number="v.time"
                  style="width: 30px"
                  type="text"
                  placeholder="空"
                  class="df-text"
                />
                小时发布</span
              >
              <i
                v-if="resolve.resolveTime.length != 1"
                class="el-icon-delete"
                style="margin-left: 20px"
                @click="delTime(i, 1)"
              ></i>
              <i
                v-if="i + 1 == resolve.resolveTime.length"
                class="el-icon-plus"
                style="margin-left: 20px"
                @click="addTime(1)"
              ></i>
            </div>
          </template>
          <template v-for="(v, i) in resolve.absolutelyTime">
            <div v-if="resolve.releaseType == 2" :key="i" class="da-list-time">
              <template>
                <!-- <el-select
                  v-model="v.list"
                  multiple
                  filterable
                  allow-create
                  default-first-option
                  placeholder="请选择班级"
                >
                  <el-option
                    v-for="item in classListTwo"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select> -->
                <input
                  v-model="v.min"
                  type="text"
                  placeholder="空"
                  class="df-text"
                />
                题至
                <input
                  v-model="v.max"
                  type="text"
                  placeholder="空"
                  class="df-text"
                />题
              </template>
              <span style="margin-left: 8px"
                >发布时间
                <el-date-picker
                  v-model="v.time"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期时间"
                >
                </el-date-picker
              ></span>
              <i
                v-if="resolve.absolutelyTime.length != 1"
                class="el-icon-delete"
                style="margin-left: 20px"
                @click="delTime(i, 2)"
              ></i>
              <i
                v-if="i + 1 == resolve.absolutelyTime.length"
                class="el-icon-plus"
                style="margin-left: 20px"
                @click="addTime(2)"
              ></i>
            </div>
          </template>
        </div>
      </div>
      <!--封底的盒子-->
      <div v-if="getItem('backCover')" class="fm-box after">
        <div class="fm-item fm-title">
          <p>封底</p>
        </div>
        <!--第二个盒子-->
        <div class="fm-item add-img">
          <i class="el-icon-plus"></i>
        </div>
        <div class="fm-item">
          <el-dropdown class="el-menu-list">
            <span class="el-dropdown-link">
              <i class="el-icon-more"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>应用</el-dropdown-item>
              <el-dropdown-item @click.native="linkTo()">编辑</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <img class="img-style" src="~@/assets/imgs/blockchain.png" alt="" />
        </div>
        <div class="fm-item">
          <el-dropdown class="el-menu-list">
            <span class="el-dropdown-link">
              <i class="el-icon-more"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>应用</el-dropdown-item>
              <el-dropdown-item @click.native="linkTo()">编辑</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <img class="img-style" src="@/assets/imgs/remote.png" alt="" />
        </div>
        <div class="fm-item">
          <el-dropdown class="el-menu-list">
            <span class="el-dropdown-link">
              <i class="el-icon-more"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>应用</el-dropdown-item>
              <el-dropdown-item @click.native="linkTo()">编辑</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <img class="img-style" src="@/assets/imgs/media.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { subjectOptions } from "@/core/util/constdata";
import { getStore } from "@/core/util/store";
// import { questionType } from "@/core/util/constdata";
import { getObj, postImg } from "@/core/api/analy/analyreportsubject";
import { addObj, putObj } from "@/core/api/analy/analytemplate";
import { mapGetters } from "vuex";
import { getExamQuestionTypeByLevelAndSubject } from "@/core/api/exam/exampaper";
// import { set } from "vue/types/umd";
export default {
  name: "ReportStructure",
  data() {
    return {
      allTitle: "",
      subjectOptions: subjectOptions,
      //编辑的时候组装好一个
      subEditData: {},
      subjectId: "",
      title: getStore({ name: "title" }),
      answerList: [
        { name: "答案", id: 1 },
        { name: "分析", id: 2 },
        { name: "点评", id: 3 },
        { name: "解答", id: 4 },
      ],
      //三个默认封面的数据
      onoCoverImg:
        "https://static.wtjy.com/analy/report/cover/img/7394c7ba93344a57a3f32e5fd52433e7.jpg",
      twoCoverImg:
        "https://static.wtjy.com/anay/report/cover/img/18a8a2ab17b9491fa622c227400ac124.png",
      threeCoverImg:
        "https://static.wtjy.com/anay/report/cover/img/b7948ecb806a488eaae6d0a7c019a4fb.png",
      //弹出框
      upImgModel: false,
      upImgHeader: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
      },
      fileList: [],
      dialogVisible: false,
      questionOrders: [],
      checkList: [],
      radio: "",
      //上一页保存的数据
      studentData: {},
      //封面
      // coverMap: "",
      subFromList: {
        //封面
        coverMap:
          "https://static.wtjy.com/analy/report/cover/img/7394c7ba93344a57a3f32e5fd52433e7.jpg",
        //扉页
        titlePage: "",
        //封底
        backCover: "",
        //成绩分析模板
        reportStructures: [],
        // 试题二维码
        QRCode: "1",
        // 错因
        wrongCause: "1",
        // 考点
        testSite: "1",
        // 回归教材
        backToTextbook: "",
        // 答案设置
        answerSetting: [1],
      },

      //这一页可能需要提交的数据
      // 年级必做题
      gradeQuestions: {
        //为1自动，为2手动
        type: "1",
        //自动推送的数量
        quantity: "1",
        // 个性题位置 1：前 2：后 3：中
        position: "",
        // 个性题为中的位置
        positionNum: "",
      },
      // 自定义的题列表
      gradeMustQuestionNums: [],
      //班级必做题
      classQuestions: {
        //为1自动，为2手动
        type: "1",
        quantity: "1",
        position: "",
        positionNum: "",
      },
      // 班级题的列表
      classMustQuestionNums: [],
      classRadio: Number,
      // 区域列表
      regionList: [],
      // 已选区域列表
      regionListSuc: [],
      // 已被选择的区域列表
      sucList: [],
      //签字区域设置
      signatureText: 3,
      resolve: {
        // 答案是否线上发布
        type: "2",
        //1:相对发布 2绝对发布
        releaseType: "1",
        //相对发布的列表
        resolveTime: [{ time: "1", min: 1, max: 3 }],
        //绝对发布的列表
        absolutelyTime: [{ time: "", min: 1, max: 3 }],
      },
      classListOne: [],
      classListTwo: [],
      tiemOption: [],
      newKeys: false,
      menuList: [
        {
          name: "学生报告",
          type: 0,
          isClick: true,
        },
        {
          name: "群体报告",
          type: 3,
          isClick: false,
        },
        {
          name: "班级报告",
          type: 1,
          isClick: false,
        },
        {
          name: "年级报告",
          type: 2,
          isClick: false,
        },
      ],
    };
  },
  computed: { ...mapGetters(["getKeepArr"]) },
  watch: {
    questionOrders() {
      if (
        this.classMustQuestionNums.length > 0 &&
        this.classListOne.length > 0
      ) {
        // console.log(this.questionOrders);
        //判断被删除的班级，从以前的列表中删除
        let newArray = [];
        let newArraytype = [];
        this.classMustQuestionNums.forEach((index) => {
          if (this.classListOne.indexOf(index.classNum) != -1) {
            newArray.push(index);
            newArraytype.push(index.classNum);
          }
        });
        //判断新加的班级，重新添加进去
        let newClassList = [];
        this.classListOne.forEach((index) => {
          if (newArraytype.indexOf(index) == -1) {
            newClassList.push({
              classNum: index,
              questionOrders: new Array(),
            });
          }
        });
        this.questionOrders.forEach((i) => {
          newClassList.map((index) => {
            index.questionOrders.push({
              examName: i.examName,
              examPaperId: i.examPaperId,
              questionItem: new Array(),
            });
          });
        });
        newArray = newArray.concat(newClassList);
        // console.log(newArray);
        // console.log(newClassList);
        // console.log(this.classListOne);
        this.classMustQuestionNums = newArray;
        if (this.classMustQuestionNums.length > 0) {
          this.classRadio = this.classMustQuestionNums[0].classNum;
        }
        this.newKeys = true;
      }
    },
    //封面第三张的url https://static.wtjy.com/anay/report/cover/img/b7948ecb806a488eaae6d0a7c019a4fb.png
    // dialogVisible() {
    //   this.sucList = [];
    //   this.regionListSuc.forEach(i => {
    //     this.sucList.push(i.name);
    //   });
    // }
    // resolve: {
    //   deep: true,
    //   handler: function() {
    //     // console.log(this.tiemOption);
    //     if (this.resolve.type == 1) {
    //       if (this.resolve.releaseType == 1) {
    //         let arr = [];
    //         this.resolve.resolveTime.forEach(i => {
    //           arr = arr.concat(i.list);
    //         });
    //         this.classListOne = [];
    //         this.tiemOption.forEach(i => {
    //           if (arr.indexOf(i.classNum) == -1) {
    //             this.classListOne.push(i.classNum);
    //           }
    //         });
    //       } else {
    //         let arr = [];
    //         this.resolve.absolutelyTime.forEach(i => {
    //           arr = arr.concat(i.list);
    //         });
    //         this.classListTwo = [];
    //         this.tiemOption.forEach(i => {
    //           if (arr.indexOf(i.classNum) == -1) {
    //             this.classListTwo.push(i.classNum);
    //           }
    //         });
    //       }
    //     }
    //   }
    // }
  },
  created() {
    this.getData();
    // 获取页面所需要的信息
    this.classOption();
    // 获取区域列表
    if (!this.$route.query.reportIdNum) {
      if (this.$route.query.type == 0) {
        this.subFromList.reportStructures = [
          "101",
          "102",
          "103",
          "104",
          "105",
          "106",
          "999",
        ];
      }
      if (this.$route.query.type == 1) {
        this.subFromList.reportStructures = [
          "201",
          "202",
          "203",
          "204",
          "205",
          "999",
        ];
      }
      if (this.$route.query.type == 2) {
        this.subFromList.reportStructures = [
          "301",
          "302",
          "303",
          "304",
          "305",
          "999",
        ];
      }
      if (this.$route.query.type == 3) {
        this.subFromList.reportStructures = [
          "401",
          "402",
          "403",
          "404",
          "405",
          "999",
        ];
      }
    }
  },
  mounted() {
    // console.log(this.getKeepArr);
    // console.log(this.getKeepArr.length);
    if (this.getKeepArr.length == 0) {
      this.$message({
        message: "缓存读取失败，请勿通过链接进入页面或者刷新页面",
        type: "warning",
      });
      setTimeout(() => {
        this.$router.push({
          name: "manageList",
          query: this.$route.query,
        });
      }, 1000);
    }

    // keepArr
  },
  methods: {
    goBack() {
      // this.$router.go(-1);
      this.$router.push({
        name: "EditReport",
        query: this.$route.query,
      });
    },
    setImg(val) {
      this.subFromList.coverMap = this[val];
    },
    // 上传图片
    changeImg(e, type) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      let url = "analy-report-cover-img";
      postImg(url, formData).then((res) => {
        this.subFromList[type] = res.data.data.url;
        this.$refs.imgNode.value = null;
      });
    },
    addTime(type) {
      if (type == 1) {
        this.resolve.resolveTime.push({ time: "", min: "", max: "" });
      } else {
        this.resolve.absolutelyTime.push({ time: "", min: "", max: "" });
      }
    },
    delTime(i, type) {
      if (type == 1) {
        this.resolve.resolveTime.splice(i, 1);
      } else {
        this.resolve.absolutelyTime.splice(i, 1);
      }
    },
    getSubjectList(levelId, subjectId) {
      let data = new URLSearchParams();
      data.append("level", levelId);
      data.append("subjectId", subjectId);

      getExamQuestionTypeByLevelAndSubject(data).then((res) => {
        // 判斷下  如果题型不对 就重新赋值
        if (this.regionListSuc && this.regionListSuc.length > 0) {
          if (this.regionListSuc[0].level != this.levelId) {
            this.subFromList.regionListSuc = [];
            this.regionListSuc = [];
          }
        }
        if (!this.subFromList.regionListSuc) {
          res.data.data.resQuestionTypes.forEach((i) => {
            this.regionListSuc.push({
              id: i.id,
              level: i.level,
              subjectId: i.subjectId,
              name: i.name,
              val: 1,
            });
          });
        }
      });
    },
    classOption() {
      getObj(this.$route.query.id).then((res) => {
        this.allTitle = res.data.data.reportName;
        subjectOptions.forEach((i) => {
          if (i.value == res.data.data.subjectId) {
            this.allTitle = this.allTitle + ` (${i.label})`;
          }
        });
        this.questionOrders = res.data.data.questionOrders;
        // console.log(this.questionOrders);
        this.subjectId = res.data.data.subjectId;
        this.getSubjectList(res.data.data.levelId, res.data.data.subjectId);
        // console.log(this.gradeMustQuestionNums);
        //存在就是编辑   //如果等于一，就清空赋值
        if (this.gradeQuestions.type == 1) {
          this.gradeMustQuestionNums = [];
        }
        if (this.classQuestions.type == 1) {
          this.classMustQuestionNums = [];
        }
        this.questionOrders.map((i) => {
          i.examQuestionOrders = i.examQuestionOrders.split(";");
          // console.log(this.gradeQuestions.type == 1);
          // console.log(!this.$route.query.reportIdNum);
          if (
            !this.$route.query.reportIdNum ||
            this.gradeQuestions.type == 1 ||
            this.gradeMustQuestionNums.length == 0
          ) {
            this.gradeMustQuestionNums.push({
              examName: i.examName,
              examPaperId: i.examPaperId,
              valArr: [],
            });
          }
        });
        // console.log(this.gradeMustQuestionNums);
        //如果存在reportIdNum就是编辑
        if (
          !this.$route.query.reportIdNum ||
          this.classQuestions.type == 1 ||
          this.classMustQuestionNums.length == 0
        ) {
          res.data.data.classNames.split(",").forEach((i) => {
            if (
              this.classMustQuestionNums.filter((item) => item.classNum == i)
                .length == 0
            ) {
              this.classMustQuestionNums.push({
                classNum: i,
                questionOrders: new Array(),
              });
            }

            this.tiemOption.push({
              classNum: i,
              questionOrders: new Array(),
            });
          });
          // this.classMustQuestionNums = [...new Set(this.classMustQuestionNums)];
          // console.log(this.classMustQuestionNums);
          res.data.data.questionOrders.forEach((i) => {
            this.classMustQuestionNums.map((index) => {
              index.questionOrders.push({
                examName: i.examName,
                examPaperId: i.examPaperId,
                questionItem: new Array(),
              });
            });
          });
        }
        // console.log(this.classMustQuestionNums);

        this.classListOne = res.data.data.classNames.split(",");
        this.classListTwo = res.data.data.classNames.split(",");

        // this.resolve.resolveTime[0].list = this.classListOne;
      });
    },
    //判断是否显示
    getItem(val) {
      // console.log(this.studentData);   variantTraining
      if (
        this.studentData.reportStructure.filter((item) => item == val).length >
        0
      ) {
        return true;
      }
      if (
        this.studentData.layeredExpandList.filter((item) => item == val)
          .length > 0
      ) {
        return true;
      }
      if (
        this.studentData.variantTrainingList.filter((item) => item == val)
          .length > 0
      ) {
        return true;
      }
    },
    getData() {
      this.studentData = getStore({ name: "reportObj" });
      if (this.$route.query.reportIdNum) {
        this.subFromList = Object.assign(
          this.subFromList,
          getStore({ name: "reportObj" })
        );
        // console.log(this.subFromList.resolve);
        if (this.subFromList.resolve) {
          this.resolve = this.subFromList.resolve;
        }
        if (this.subFromList.regionListSuc) {
          this.regionListSuc = this.subFromList.regionListSuc;
        }
        if (this.subFromList.signatureText) {
          this.signatureText = this.subFromList.signatureText;
        }
        // submitData.regionListSuc = this.regionListSuc;
        // submitData.signatureText = this.signatureText;

        if (this.studentData.gradeQuestions.type) {
          this.gradeQuestions = this.studentData.gradeQuestions;
          this.gradeMustQuestionNums = this.studentData.gradeMustQuestionNums;
        }
        if (this.studentData.classQuestions.type) {
          this.classQuestions = this.studentData.classQuestions;
          this.classMustQuestionNums = this.studentData.classMustQuestionNums;
        }

        // this.resolve = this.studentData.resolve;

        //组装一下编辑情况下需要提交的上一个页面的数据
        this.subEditData.scoreRate = this.studentData.scoreRate;
        this.subEditData.simpleDefinition = this.studentData.simpleDefinition;
        this.subEditData.difficultyOption = this.studentData.difficultyOption;
        this.subEditData.difficultyStart = this.studentData.difficultyStart;
        this.subEditData.questionType = this.studentData.questionType;
        this.subEditData.sendList = this.studentData.sendList;
        this.subEditData.sendPriority = this.studentData.sendPriority;
        this.subEditData.wrongPriority = this.studentData.wrongPriority;
        this.subEditData.answerLocation = this.studentData.answerLocation;
        this.subEditData.isShowOriginal = this.studentData.isShowOriginal;
        this.subEditData.OrderOfItems = this.studentData.OrderOfItems;
        this.subEditData.reportStructure = this.studentData.reportStructure;
        this.subEditData.layeredExpandList = this.studentData.layeredExpandList;
        this.subEditData.variantTrainingList =
          this.studentData.variantTrainingList;
        //  layeredExpandList variantTrainingList
        this.subEditData.proportion = this.studentData.proportion;
        this.subEditData.layeredExtensionQuestion =
          this.studentData.layeredExtensionQuestion;
        this.subEditData.customGroup = this.studentData.customGroup;
        this.subEditData.classPushlist = this.studentData.classPushlist;
        this.subEditData.coverTitleOption = this.studentData.coverTitleOption;
        this.subEditData.coverTitle = this.studentData.coverTitle;
        this.subEditData.isShowExamName = this.studentData.isShowExamName;
        this.subEditData.isShowExamDate = this.studentData.isShowExamDate;
        this.subEditData.examDateOptions = this.studentData.examDateOptions;
        this.subEditData.examDate = this.studentData.examDate;
        let arr = [
          "封面",
          "成绩分析",
          "试题",
          "错因",
          "考点",
          "年级必做题",
          "班级必做题",
          "答案解析",
          "成绩分析各模块",
          "发布答案时间",
          "试题二维码",
        ];
        arr.forEach((item) => {
          this.subEditData.reportStructure =
            this.subEditData.reportStructure.filter((index) => index != item);
        });
        if (this.$route.query.type == 0) {
          this.subEditData.smallQuestionScore =
            this.studentData.smallQuestionScore;
        }

        if (this.$route.query.type == 1 || this.$route.query.type == 2) {
          this.subEditData.scoreRateBeforeValue =
            this.studentData.scoreRateBeforeValue;
          this.subEditData.scoreRateEndValue =
            this.studentData.scoreRateEndValue;
          this.subEditData.consolidationOption =
            this.studentData.consolidationOption;
          this.subEditData.sameLevelOption = this.studentData.sameLevelOption;
          this.subEditData.sameLevelClassList =
            this.studentData.sameLevelClassList;
          this.subEditData.focusOnMaxAmount = this.studentData.focusOnMaxAmount;
        }
      }
      // console.log(this.subEditData);
    },
    subFrom() {
      // 提交之前判断数据进行组装
      // 拼接一个提交对象出来
      let submitData = {};
      if (this.getItem("cover")) {
        submitData.coverMap = this.subFromList.coverMap;
      }
      if (this.getItem("analyse")) {
        submitData.reportStructures = this.subFromList.reportStructures;
      }
      // if (this.getItem("试题")) {
      //   submitData.reportStructures = this.subFromList.reportStructures;
      // }
      // if (this.getItem("错因")) {
      //   submitData.wrongCause = this.subFromList.wrongCause;
      // }
      if (this.getItem("analysis")) {
        submitData.answerSetting = this.subFromList.answerSetting;
        // submitData.resolve = this.resolve;
      }
      // console.log(submitData.answerSetting);
      // if (this.getItem("考点")) {
      //   submitData.testSite = this.subFromList.testSite;
      // }
      if (this.getItem("gradeNecessary")) {
        submitData.gradeQuestions = this.gradeQuestions;
        submitData.gradeMustQuestionNums = this.gradeMustQuestionNums;
      } else {
        submitData.gradeQuestions = {};
        submitData.gradeMustQuestionNums = [];
      }
      if (this.getItem("classNecessary")) {
        submitData.classQuestions = this.classQuestions;
        submitData.classMustQuestionNums = this.classMustQuestionNums;
      } else {
        submitData.classQuestions = {};
        submitData.classMustQuestionNums = [];
      }
      let typeResolve = false;
      let typeCross = false;
      let typeVal = 0;
      // debugger;
      let typeResolveVal = false;
      // let typeCrossVal =false
      if (this.getItem("qrCode")) {
        // if (this.resolve.type == 1) {
        if (this.resolve.releaseType == 1) {
          this.resolve.resolveTime.forEach((index) => {
            if (Number(index.min) > Number(index.max)) {
              typeResolve = true;
            }
            if (Number(index.min) > Number(typeVal)) {
              typeVal = index.max;
            } else {
              typeCross = true;
            }
            if (index.time == "") {
              typeResolveVal = true;
            }
          });
        } else {
          this.resolve.absolutelyTime.forEach((index) => {
            if (Number(index.min) > Number(index.max)) {
              typeResolve = true;
            }
            if (Number(index.min) > Number(typeVal)) {
              typeVal = index.max;
            } else {
              typeCross = true;
            }
            if (index.time == "") {
              typeResolveVal = true;
            }
          });
        }
        // } else {
        //   submitData.resolve = this.resolve;
        // }
      }
      //       resolve: {
      //   // 答案是否线上发布
      //   type: "2",
      //   //1:相对发布 2绝对发布
      //   releaseType: "1",
      //   //相对发布的列表
      //   resolveTime: [{ time: "1", min: 1, max: 3 }],
      //   //绝对发布的列表
      //   absolutelyTime: [{ time: "", min: 1, max: 3 }]
      // },
      if (typeResolveVal) {
        this.$message({
          message: "发布时间不能为空",
          type: "warning",
        });
        return;
      }
      if (typeCross) {
        this.$message({
          message: "题号存在交叉值",
          type: "warning",
        });
        return;
      }
      if (typeResolve) {
        this.$message({
          message: "题号最小值不能大于最大值",
          type: "warning",
        });
        return;
      }
      if (this.getItem("qrCode")) {
        submitData.resolve = this.resolve;
      }
      if (this.getItem("answerArea")) {
        submitData.regionListSuc = this.regionListSuc;
      }
      if (this.getItem("area")) {
        submitData.signatureText = this.signatureText;
      }
      // submitData.layeredExtensionQuestion=0
      //存在就是编辑
      if (this.$route.query.reportIdNum) {
        submitData = Object.assign(this.subEditData, submitData);
        // subEditData
        // console.log(this.subFromList);
        let data = {
          // cover: this.subFromList.coverMap,
          name: getStore({ name: "title" }),
          description: getStore({ name: "intro" }),
          setting: JSON.stringify(submitData),
          isDefault: 0,
          id: this.$route.query.reportIdNum,
          type: this.$route.query.type,
          subjectId: this.subjectId,
          reportSubjectId: this.$route.query.id,
        };
        if (this.getItem("封面")) {
          data.cover = this.subFromList.coverMap;
        }
        putObj(data).then(() => {
          this.$message.success("策略修改成功");
          let query = this.$route.query;
          delete query.reportIdNum;
          setTimeout(() => {
            this.$router.push({
              name: "manageList",
              query: query,
            });
          }, 1000);
        });
      } else {
        submitData = Object.assign(this.studentData, submitData);
        let data = {
          // cover: this.subFromList.coverMap,
          name: getStore({ name: "title" }),
          description: getStore({ name: "intro" }),
          setting: JSON.stringify(submitData),
          isDefault: 0,
          type: this.$route.query.type,
          subjectId: this.subjectId,
          reportSubjectId: this.$route.query.id,
        };
        if (this.getItem("封面")) {
          data.cover = this.subFromList.coverMap;
        }
        // studentData
        // console.log(data);
        // data = Object.assign(this.studentData, data);
        // console.log(this.studentData);
        // console.log(data);
        addObj(data).then(() => {
          // console.log(res);
          this.$message.success("策略保存成功");
          let query = this.$route.query;
          delete query.reportIdNum;
          setTimeout(() => {
            this.$router.push({
              name: "manageList",
              query: query,
            });
          }, 1000);
        });
      }
    },
    linkTo() {},
  },
};
</script>
<style lang="scss">
.reportStructure {
  .el-radio__input.is-checked .el-radio__inner {
    border-color: #2474ed;
    background: #2474ed;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: rgba(0, 0, 0, 0.85);
  }

  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-color: #2474ed;
    background: #2474ed;
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: rgba(0, 0, 0, 0.85);
  }
}
</style>
<style scoped lang="scss">
.reportStructure {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 1280px;
  margin: 40px auto;

  .cnt-box {
    padding: 64px;
    /*padding-left: 64px;*/
    .df-text {
      width: 30px;
      border-bottom: 1px solid #999999;
      text-align: center;
    }

    .qm-box {
      display: flex;
      margin-top: 16px;
    }

    .cs-box {
      padding: 10px;
      display: flex;
      .da-fb-box {
        margin-left: 12px;
        .da-list-time {
          margin-top: 8px;
        }
      }
      i {
        cursor: pointer;
      }

      .left-title {
        color: rgba(0, 0, 0, 0.5);
        width: 198px;
      }

      .right-cnt {
        .region-box {
          display: flex;
          /*flex-wrap: wrap;*/
          justify-content: space-between;
          width: 560px;
          align-items: center;
          i {
            cursor: pointer;
          }
          .region-left {
            width: 580px;
            display: flex;
            flex-wrap: wrap;
          }
        }
        .exam-box {
          display: flex;
          margin-bottom: 10px;
          /*justify-content: space-between;*/
          .exam-list {
            width: 352px;
            max-height: 440px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            padding: 16px;
            border-radius: 4px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 3px;
              height: 3px;
            }

            ///*<!--修改 滑块 -->*/
            &::-webkit-scrollbar-thumb {
              background-color: #2474ed;
            }
          }

          .suc-box {
            margin-left: 32px;
            border-style: dashed;
          }
        }
      }
    }

    h3 {
      font-size: 24px;
      margin-bottom: 32px;
      font-weight: bold;
    }

    .after:last-child {
      &:before {
        bottom: 0;
        content: "";
      }
    }

    .after {
      position: relative;

      &:after,
      &:before {
        position: absolute;
        width: 768px;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.1);
        left: 0;
      }

      &:after {
        top: 0;
        content: "";
      }
    }

    .fm-box {
      display: flex;
      /*justify-content: space-between;*/
      padding: 10px 0 10px 16px;

      .add-img {
        cursor: pointer;

        .el-icon-plus {
          font-size: 14px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
        }
      }
      .add-img.trueClass {
        border-color: #2474ed;
      }

      .fm-item {
        width: 160px;
        height: 213px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        position: relative;
        margin-right: 32px;
        .add-input {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          opacity: 0;
          cursor: pointer;
        }

        .el-menu-list {
          position: absolute;
          right: 10px;
          top: 10px;
          cursor: pointer;
        }

        .img-style {
          width: 135px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
        }
      }

      .fm-title {
        border: none;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .title-box {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    padding: 16px 64px;
    align-items: center;

    .title {
      font-size: 24px;
      font-weight: bold;
    }

    .btn-box {
      .cli-btn {
        background-color: #2474ed;
        border-color: #2474ed;
      }

      .btn-text {
        color: #2474ed;
      }

      .el-icon--right {
        color: white;
      }
    }
  }
}
</style>
